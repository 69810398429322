import React from "react";




function Carousel() {
    return (

        <div className="carousel">
            <div className="image">
            <img src="Images/exteriorHouseImpact.jpg" alt="ExteriorImpact"/>
            </div>
        </div>


    );
}

export default Carousel;