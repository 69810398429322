import React from "react";




function Photos() {
    return (

        <div className="startTitle">

        <h1><strong>We Accept Financing Through YGRENE !!!</strong></h1>
         
        </div>

        
        

    );
}

export default Photos;