import React from "react";


function Footer() {
    return (
        
        <div className="footer-contents ">
            <h3>We are licensed, and Insured!!! Call today to book Appt!</h3>
        </div>
    );
}

export default Footer;